// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alserkal-advisory-js": () => import("./../../../src/pages/alserkal-advisory.js" /* webpackChunkName: "component---src-pages-alserkal-advisory-js" */),
  "component---src-pages-alserkal-advisory-our-work-js": () => import("./../../../src/pages/alserkal-advisory/our-work.js" /* webpackChunkName: "component---src-pages-alserkal-advisory-our-work-js" */),
  "component---src-pages-alserkal-advisory-upcoming-programmes-js": () => import("./../../../src/pages/alserkal-advisory/upcoming-programmes.js" /* webpackChunkName: "component---src-pages-alserkal-advisory-upcoming-programmes-js" */),
  "component---src-pages-alserkal-advisroy-beta-js": () => import("./../../../src/pages/alserkal-advisroy-beta.js" /* webpackChunkName: "component---src-pages-alserkal-advisroy-beta-js" */),
  "component---src-pages-alserkal-avenue-js": () => import("./../../../src/pages/alserkal-avenue.js" /* webpackChunkName: "component---src-pages-alserkal-avenue-js" */),
  "component---src-pages-alserkal-dummy-new-js": () => import("./../../../src/pages/alserkal-dummy-new.js" /* webpackChunkName: "component---src-pages-alserkal-dummy-new-js" */),
  "component---src-pages-community-index-js": () => import("./../../../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-test-js": () => import("./../../../src/pages/demo-test.js" /* webpackChunkName: "component---src-pages-demo-test-js" */),
  "component---src-pages-design-your-experience-js": () => import("./../../../src/pages/design-your-experience.js" /* webpackChunkName: "component---src-pages-design-your-experience-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-explore-our-district-js": () => import("./../../../src/pages/explore-our-district.js" /* webpackChunkName: "component---src-pages-explore-our-district-js" */),
  "component---src-pages-foundation-common-room-js": () => import("./../../../src/pages/foundation/common-room.js" /* webpackChunkName: "component---src-pages-foundation-common-room-js" */),
  "component---src-pages-foundation-exhibitions-commissions-js": () => import("./../../../src/pages/foundation/exhibitions-commissions.js" /* webpackChunkName: "component---src-pages-foundation-exhibitions-commissions-js" */),
  "component---src-pages-foundation-grants-js": () => import("./../../../src/pages/foundation/grants.js" /* webpackChunkName: "component---src-pages-foundation-grants-js" */),
  "component---src-pages-foundation-index-js": () => import("./../../../src/pages/foundation/index.js" /* webpackChunkName: "component---src-pages-foundation-index-js" */),
  "component---src-pages-foundation-learning-js": () => import("./../../../src/pages/foundation/learning.js" /* webpackChunkName: "component---src-pages-foundation-learning-js" */),
  "component---src-pages-foundation-residency-research-js": () => import("./../../../src/pages/foundation/residency-research.js" /* webpackChunkName: "component---src-pages-foundation-residency-research-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-our-community-js": () => import("./../../../src/pages/join-our-community.js" /* webpackChunkName: "component---src-pages-join-our-community-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-opening-hours-js": () => import("./../../../src/pages/opening-hours.js" /* webpackChunkName: "component---src-pages-opening-hours-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-perspectives-js": () => import("./../../../src/pages/perspectives.js" /* webpackChunkName: "component---src-pages-perspectives-js" */),
  "component---src-pages-plan-your-visit-js": () => import("./../../../src/pages/plan-your-visit.js" /* webpackChunkName: "component---src-pages-plan-your-visit-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-tote-js": () => import("./../../../src/pages/tote.js" /* webpackChunkName: "component---src-pages-tote-js" */),
  "component---src-pages-venues-js": () => import("./../../../src/pages/venues.js" /* webpackChunkName: "component---src-pages-venues-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */),
  "component---src-templates-article-images-page-js": () => import("./../../../src/templates/articleImagesPage.js" /* webpackChunkName: "component---src-templates-article-images-page-js" */),
  "component---src-templates-article-sounds-page-js": () => import("./../../../src/templates/articleSoundsPage.js" /* webpackChunkName: "component---src-templates-article-sounds-page-js" */),
  "component---src-templates-article-videos-page-js": () => import("./../../../src/templates/articleVideosPage.js" /* webpackChunkName: "component---src-templates-article-videos-page-js" */),
  "component---src-templates-article-words-page-js": () => import("./../../../src/templates/articleWordsPage.js" /* webpackChunkName: "component---src-templates-article-words-page-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-community-member-page-js": () => import("./../../../src/templates/communityMemberPage.js" /* webpackChunkName: "component---src-templates-community-member-page-js" */),
  "component---src-templates-event-page-js": () => import("./../../../src/templates/eventPage.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-job-page-js": () => import("./../../../src/templates/jobPage.js" /* webpackChunkName: "component---src-templates-job-page-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/pressPage.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-programme-page-js": () => import("./../../../src/templates/programmePage.js" /* webpackChunkName: "component---src-templates-programme-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/teamPage.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-venue-page-js": () => import("./../../../src/templates/venuePage.js" /* webpackChunkName: "component---src-templates-venue-page-js" */)
}

